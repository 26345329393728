export default {
  methods: {
    formatTitle (name) {
      if (name) {
        const names = name.split(',')
        const newName = []
        for (let i = 0; i < names.length; i++) {
          newName.push(
            names[i].charAt(0).toUpperCase() + names[i].substr(1).toLowerCase()
          )
        }
        return newName.join(', ')
      }
      return '-'
    },
    formatPaymentType (text) {
      let character = ''
      if (text.includes('-')) {
        character = '-'
      } else if (text.includes('_')) {
        character = '_'
      }
      const separatedText = character ? text.split(character).join(' ') : text
      return this.formatTitle(separatedText)
    },
    formatText (text) {
      return text
        ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        : ''
    }
  }
}
