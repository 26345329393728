import Vue from 'vue'
import VueRouter from 'vue-router'

const PaymentHome = () => import('@/pages/index')
const PaymentWallet = () => import('@/pages/wallet')
const PaymentSuccessful = () => import('@/pages/success')
const Error404 = () => import('@/pages/error')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: PaymentHome
  },
  {
    path: '/pay/:slug',
    name: 'payment-home',
    component: PaymentHome
  },
  {
    path: '/wallet/:slug',
    name: 'payment-wallet',
    component: PaymentWallet
  },
  {
    path: '/payment-successful',
    name: 'payment-successful',
    component: PaymentSuccessful
  },
  {
    path: '*',
    name: 'error',
    component: Error404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
